import chroma from 'chroma-js';

const emptyBg = () => '#fff' //'linear-gradient(180deg, rgba(8, 54, 107, 0.765), rgba(8, 54, 107, 0.4) 50%, rgba(8, 54, 107, 0.5) 100%), url(/static/cover.jpg) center center / cover, #08366B';

export default {
  generate(model, thumb = false) {  
    let { coverImage, colors } = model;
    if (!coverImage) {      
      // return colors?.theme === 'dark' ? '#000' : '#fff';
      coverImage = {
        image: "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg"
      },
      colors = {
        imgColor:"#9cc213",
        palette:["#31499e","#4d66af","#3851a3","#2a429a","#6781c0"],
        theme:'light'
      }
    }
    const imageUrl = thumb ? coverImage.thumb : coverImage.image;
    const p = colors.palette;
    if (colors.theme === 'light') {      
      const c1 = chroma(p[4]).alpha(0.6);
      const c2 = chroma(p[3]).alpha(0.1);
      return `linear-gradient(180deg, ${c1} 0%, ${c2} 0%), url(${imageUrl}) center center / cover, ${colors.imgColor}`;
    }
    const c1 = chroma(p[0]).alpha(0.6);
    const c2 = chroma(p[1]).alpha(0.1);
    return `linear-gradient(180deg, ${c1} 0%, ${c2} 0%), url(${imageUrl}) center center / cover, ${colors.imgColor}`;
  },
  box(model: { colors: { palette: string | any[]; theme: string; }; }) {
    if (!model.colors) return '#fff';

    if (!model.colors.palette?.length) return model.colors?.theme == 'light' ? '#fff' : '#000';
    if (model.colors.theme === 'light') {
      return chroma(model.colors.palette[2]).alpha(0.9);
    }
    return chroma(model.colors.palette[2]).alpha(0.9);
  },
};
