const formatAmountForDisplay = (
  amount: number = 0,
  floor = true,
  symbol = true,
  endDigits = false,
) => {
  const numberFormat = new Intl.NumberFormat(["en-US"]);
  const floored = Math.floor(amount);
  let digits;
  let digitsDisplay = "";
  if (endDigits) {
    const newAmount = parseFloat(amount?.toFixed(3));
    const newFloored = Math.floor(newAmount);
    digitsDisplay = ".00";
    digits = parseFloat((newAmount * 100 - newFloored * 100).toFixed(2));
  } else digits = Math.floor(amount * 100 - floored * 100);

  if (digits && (!floor || amount < 10)) {
    if (digits < 10) {
      digitsDisplay = `.0${digits}`;
    } else {
      digitsDisplay = `.${digits}`;
    }
  }
  return (symbol ? "$" : "") + numberFormat.format(floored) + digitsDisplay;
};

export default formatAmountForDisplay;
