import router from "next/router";
import React, { createContext, useState } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import Slider from "react-slick";
import ProgressChart from "../../components/charts/progressChart";
import { privateRequestState } from "../../utils/constant";
import formatAmountForDisplay from "../../utils/currency";
import Bg from "../../utils/imageBackgrounds";
import { Completionist, renderer } from "../../utils/offerUtils";
import LoginModal from "../auth/LoginModal";
import formatRatesForDisplay from "../../utils/rate";

const Image = ({ offer, title, raised }) => {
  if (offer.coverImage === 0 || offer.coverImage === null) {
    (offer.coverImage = {
      image:
        "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg",
      thumb:
        "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg",
    }),
      (offer.colors = {
        imgColor: "#9cc213",
        palette: ["#31499e", "#4d66af", "#3851a3", "#2a429a", "#6781c0"],
        theme: "light",
      });
  }

  return (
    <Card
      className="card-img offer-card invert"
      style={{
        color: offer.colors?.theme === "dark" ? "#fff" : "#000",
        background: `url(${offer.coverImage?.thumb}) center center / cover`,
      }}
    >
      <div
        className="img-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {title ? (
          <div
            style={{
              alignSelf: "end",
              width: "100%",
              background: Bg.box(offer),
            }}
          >
            <Card.Body>
              {
                {
                  wholesale: <Badge variant="warning">Wholesale</Badge>,
                  retail: <Badge variant="success">Retail</Badge>,
                }[offer.type]
              }
              <Card.Title className="mb-0">
                <Title offer={offer} />
              </Card.Title>
              {(offer.status === "active" || offer.status === "private") && (
                <>
                  <small>Min: </small>
                  <Badge variant="success">
                    {offer.minInvestment % 1 !== 0
                      ? formatRatesForDisplay(
                          offer.minInvestment,
                          false,
                          2,
                          false
                        )
                      : formatAmountForDisplay(Math.round(offer.minInvestment))}
                  </Badge>
                  &nbsp;-&nbsp;
                  <small>Target: </small>
                  <Badge variant="success">
                    {formatAmountForDisplay(offer.target)}
                  </Badge>
                  <br />
                  <small>Raised: </small>
                  <Badge variant="success">
                    {formatAmountForDisplay(raised + offer?.strideInvestment)}
                  </Badge>
                </>
              )}
            </Card.Body>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

const Title = ({ offer }) => <b>{offer.companyName}</b>;

const OfferCard = ({ offer, user, interest }) => {
  var dayjs = require("dayjs");
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  const CountDownContext = createContext();
  const [offerValues, setOfferValues] = useState(offer);

  const chartColours = ["#CC744C", "#4A91C5"];
  const chartConfigs = {
    width: 100,
    height: 100,
    cx: 50,
    cy: 50,
    innerRadius: 36,
    outerRadius: 44,
  };

  if (offer.coverImage === 0 || offer.coverImage === null) {
    (offer.coverImage = {
      image:
        "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg",
      thumb:
        "https://strideequity-non-prod-s3.s3.ap-southeast-1.amazonaws.com/uploads/default-cover.svg",
    }),
      (offer.colors = {
        imgColor: "#9cc213",
        palette: ["#31499e", "#4d66af", "#3851a3", "#2a429a", "#6781c0"],
        theme: "light",
      });
  }

  // offer card INVEST NOW button text
  let requestAccessButtonText = "Request Access";

  // if the user is a member
  if (user?.role == "member") {
    // if the member has requested for the private access
    if (offer.acceptedPrivateRequest) {
      requestAccessButtonText =
        offer.acceptedPrivateRequest === privateRequestState.accepted
          ? // if private access is accepted
            "Invest Now"
          : // if private access pending or rejected
            "Access Requested";
    }
  }

  // if the user is an admin or if the user is an investee and
  if (
    user?.role == "admin" ||
    offer?.UserId === user?.id ||
    interest?.includes(offer?.id)
  ) {
    // directly display the invert now button
    requestAccessButtonText = "Invest Now";
  }

  const handlePrivateInvestNowButton = () => {
    if (!user?.id) {
      LoginModal.register(`/offer/${offer.id}`);
      return;
    }
  };

  return (
    <Card
      as="a"
      className="offer-card"
      style={{ height: "100%" }}
      onClick={() => {
        if (offer.status == "private" || offer.status == "private ended") {
          if (!user?.id) {
            return LoginModal.register(`/offer/${offer.slug}/${offer.id}`);
          } else {
            return router.push(`/offer/${offer.slug}/${offer.id}`);
          }
        }

        router.push(`/offer/${offer.slug}/${offer.id}`);
      }}
    >
      {
        {
          wholesale: (
            <Badge className="offer-label wholesale">{`Wholesale ${
              offer.status == "private" || offer.status == "private ended"
                ? "(Private)"
                : ""
            }`}</Badge>
          ),
          retail: (
            <Badge className="offer-label retail">{`Retail ${
              offer.status == "private" || offer.status == "private ended"
                ? "(Private)"
                : ""
            }`}</Badge>
          ),
        }[offer.type]
      }

      <div className="offer-card-img">
        <Card.Img
          variant="top"
          src={offer.coverImage?.thumb}
          href={offer.status != "private" ? `/offer/${offer.slug}` : ""}
        />
      </div>
      <Card.Body className="p-0 offer-card-title">
        <Card.Title className="mb-0">{offer.companyName}</Card.Title>
        {(offer.status === "active" || offer.status === "offer ended") && (
          <div className="offer-card-investment-indicator">
            <ProgressChart
              offer={offer}
              colours={chartColours}
              chartConfigs={chartConfigs}
              coInvestorAmount={offer.raised}
            />
          </div>
        )}
      </Card.Body>
      <Card.Body className="wrap-offer-description p-0">
        <Card.Text className="offer-description text-left mb-2">
          <div
            className="description-o"
            dangerouslySetInnerHTML={{
              __html: offer.pitch,
            }}
          />
        </Card.Text>
      </Card.Body>
      <Card.Footer className="p-0 mt-2">
        <div className="mb-3">
          {(offerValues.status == "EOI" ||
            offerValues.status == "EOI ended" ||
            offerValues.status == "active" ||
            offerValues.status == "private" ||
            offerValues.status == "private ended" ||
            offerValues.status == "offer ended") &&
          offerValues.endDate !== null ? (
            <div className="offer-countdown-wrapper text-center">
              <CountDownContext.Provider value={offerValues}>
                <Countdown
                  date={dayjs(offerValues.endDate)
                    .local()
                    .format("YYYY-MM-DDTHH:mm:ss")}
                  onComplete={() =>
                    Completionist(offerValues, null, "", setOfferValues)
                  }
                  renderer={(props) => renderer(props, offerValues)}
                />
              </CountDownContext.Provider>
            </div>
          ) : (
            ""
          )}
        </div>

        {
          {
            active: (
              <Button variant="outline-dark" block>
                Invest Now
              </Button>
            ),
            "offer ended": (
              <Button variant="outline-dark" block>
                View Investment
              </Button>
            ),
            EOI: (
              <Button variant="outline-dark" block>
                Express Interest
              </Button>
            ),
            "EOI ended": (
              <Button variant="outline-dark" block>
                View EOI
              </Button>
            ),
            private: (
              <Button
                variant="outline-dark"
                block
                onClick={handlePrivateInvestNowButton}
              >
                {requestAccessButtonText}
              </Button>
            ),
            "private ended": (
              <Button variant="outline-dark" block>
                View Investment
              </Button>
            ),
          }[offerValues.status]
        }
      </Card.Footer>
    </Card>
  );
};

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  centerPadding: "40px",
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.05,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        //centerMode: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
  ],
};

const Grid = ({ offers, user = null, interest = [] }) => {
  return (
    <Row>
      {offers?.map((offer) => (
        <Col
          lg={3}
          md={4}
          sm={6}
          key={offer.id}
          className="row-eq-height"
          style={{ marginBottom: "30px" }}
        >
          <OfferCard offer={offer} user={user} interest={interest} />
        </Col>
      ))}
    </Row>
  );
};

const Carousel = ({ offers, user = null, interest = [] }) => {
  let filteredOffers = offers
    .filter((offer) => offer.status == "active" || offer.status == "private")
    .reverse();

  if (filteredOffers.length > 9) {
    filteredOffers = filteredOffers.slice(-10);
  } else {
    let EOIOffers = offers
      .filter((offer) => offer.status == "EOI")
      .slice(filteredOffers.length - 10)
      .reverse();
    filteredOffers = filteredOffers.concat(EOIOffers);
  }

  sliderSettings.infinite = filteredOffers.length > 4 ? true : false;

  for (let i = 0; i < sliderSettings.responsive.length; i++) {
    sliderSettings.responsive[i].settings.infinite =
      filteredOffers.length > sliderSettings.responsive[i].settings.slidesToShow
        ? true
        : false;
  }

  return (
    <Slider className="offer-carousel" {...sliderSettings}>
      {filteredOffers.map((offer) => (
        <div key={offer.id} className="offer-carousel-card">
          <OfferCard offer={offer} user={user} interest={interest} />
        </div>
      ))}
    </Slider>
  );
};

OfferCard.Grid = Grid;
OfferCard.Image = Image;
OfferCard.Title = Title;
OfferCard.Carousel = Carousel;

export default OfferCard;
