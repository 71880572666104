import moment from "moment";
import Api from "./apiCall";
import { StatusList } from "./constant";

const statusNewList = StatusList;

// Renderer callback with condition
export const renderer = (props, offerData, mode, updates, setUpdated) => {
  if (props.completed) {
    Completionist(offerData, updates, mode, setUpdated);
    // Render a completed state
    return (
      <span className="offer-status-card ">
        {
          {
            active: "Offer ended",
            "offer ended": "Offer ended",
            EOI: "EOI ended",
            "EOI ended": "EOI ended",
            "private ended": "Private offer ended",
          }[offerData.status]
        }
      </span>
    );
  } else { 
    // Render a countdown
    return (offerData.status == StatusList.Active ||
      offerData.status == StatusList.private ||
      offerData.status == StatusList.EOI) &&
      !mode ? (
      <div className="offer-countdown count-left">
        {props.days > 0 ? (
          <div>
            <span>
              {props.days}
              {/* {props.days}d {props.hours}h {props.minutes}m {props.seconds}s */}
            </span>
            {props.days === 1 ? "day" : "days"} left
          </div>
        ) : (
          <div>
            Closes in {props.hours}h {props.minutes}m {props.seconds}s
          </div>
        )}
      </div>
    ) : (
      ""
    );
  }
};

//Executes on countdown timer ends
export const Completionist = async (offerData, updates, mode, setUpdated) => {
  let status = "";
  let host = process.env.HOSTING_URL;
  var dayjs = require("dayjs");
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  if (mode !== "edit") {
    if (offerData.status === statusNewList.EOI) {
      status = statusNewList.EOIEnded;
    } else if (offerData.status === statusNewList.Active) {
      status = statusNewList.OfferEnded;
    }
    if (status && offerData.endDate) {
      updates = {
        ...updates,
        id: offerData.id,
        endDate: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        status: status,
      };

      let resp = await fetch(host + `api/offer`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updates),
      });

      if (resp && setUpdated) {
        updates = {
          ...offerData,
          status: updates.status,
          endDate: updates.endDate,
        };

        setUpdated(updates);
      }
    }
  }
};
