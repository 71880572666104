const formatRatesForDisplay = (
  amount: number = 0,
  symbol = true,
  decimalPlaces = 2,
  displayThirdZero = true
) => {
  const numberFormat = new Intl.NumberFormat(["en-US"]);
  const floored = Math.floor(amount);
  const digits = Math.floor(
    Math.round(amount * Math.pow(10, decimalPlaces)) -
      Math.round(floored * Math.pow(10, decimalPlaces))
  );

  let digitsDisplay = decimalPlaces === 2 ? ".00" : ".000";
  if (digits) {
    if (
      (decimalPlaces === 2 && digits < 10) ||
      (decimalPlaces === 3 && digits < 100 && digits > 9)
    ) {
      digitsDisplay = `.0${digits}`;
    } else if (decimalPlaces === 3 && digits < 10) {
      digitsDisplay = `.00${digits}`;
    } else {
      digitsDisplay = `.${digits}`;
    }
  }

  // return formatted amount with out decimal places
  if (decimalPlaces == 0) {
    digitsDisplay = "";
  }

  // if display third zero param is false and the last digit of the floating number is 0, then we remove that last 0
  // even if decimalPlaces is passed as 3
  if (digitsDisplay.length > 3 && displayThirdZero == false) {
    //last digit of the decimal numbers
    const lastDigit = digitsDisplay[3];

    if (lastDigit === "0") {
      //preparing digitDisplay with the index of 1 and 2.
      //in here index 0 is for "."
      digitsDisplay = `.${digitsDisplay[1]}${digitsDisplay[2]}`;
    }
  }

  return (
    (!symbol ? "$" : "") +
    numberFormat.format(floored) +
    digitsDisplay +
    (symbol ? "%" : "")
  );
};

export default formatRatesForDisplay;
