import React from 'react'
import { PieChart, Pie, Cell } from 'recharts';

const ProgressChart = ({ offer, colours, chartConfigs, coInvestorAmount }) => {
  let raisedPercentage =
    offer.sharePrice == null
      ? 0
      : Math.round(
          ((coInvestorAmount + offer.strideInvestment) / offer.target) * 100
        );

  const perAngel =
    360 -
    (360 * (offer.target - (coInvestorAmount + offer.strideInvestment))) /
      offer.target -
    90;

  const angleValue =
    offer.target < offer.strideInvestment + coInvestorAmount
      ? 360 / offer.target
      : 360 / (offer.strideInvestment + coInvestorAmount);

  const data = [
    { name: "Stride Investment", value: angleValue * offer.strideInvestment },
    { name: "Co-investor Investment", value: angleValue * coInvestorAmount },
  ];

  return (
    <div className="offer-progress-chart">
      <div className="offer-target-circle">
        <p>{raisedPercentage}%</p>
        <label>of minimum target reached</label>
      </div>
      <PieChart width={chartConfigs.width} height={chartConfigs.height}>
        <Pie
          data={data}
          cx={chartConfigs.cx}
          cy={chartConfigs.cy}
          innerRadius={chartConfigs.innerRadius}
          outerRadius={chartConfigs.outerRadius}
          startAngle={90}
          endAngle={-perAngel}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colours[index % colours.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default ProgressChart;